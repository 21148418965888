<template>
  <v-menu bottom offset-y style="z-index: 1000">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on" class="mr-4">
        <v-badge
          avatar
          bottom
          :color="sessionBadgeColor"
          offset-x="18"
          offset-y="18"
        >
          <template v-if="getUser">
            <v-avatar v-if="getUser.photo">
              <v-img :src="getUser.photo" :aspect-ratio="1 / 1">
                <template v-slot:placeholder>
                  <v-skeleton-loader type="avatar"></v-skeleton-loader>
                </template>
              </v-img>
            </v-avatar>
            <v-avatar v-else>
              <v-icon color="primary" class="text-h3"
                >mdi-account-circle</v-icon
              >
            </v-avatar>
          </template>
          <v-avatar v-else>
            <v-icon color="primary" class="text-h3">mdi-account-circle</v-icon>
          </v-avatar>
        </v-badge>
      </v-btn>
    </template>

    <v-sheet max-width="350px">
      <v-list>
        <v-list-item-group>
          <v-list-item @click="$router.push('user-profile')">
            <v-list-item-icon class="mr-4">
              <template v-if="getUser">
                <v-avatar v-if="getUser.photo">
                  <v-img
                    :src="getUser.photo"
                    :alt="'Foto de ' + getUser.name"
                    :aspect-ratio="1 / 1"
                  />
                </v-avatar>
                <v-icon v-else color="primary" x-large
                  >mdi-account-circle</v-icon
                >
              </template>
              <v-icon v-else color="primary" x-large>mdi-account-circle</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="text-h6">{{
                getUser ? getUser.name : ""
              }}</v-list-item-title>
              <v-list-item-title class="text-caption">{{
                getUser ? getUser.email : ""
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <RenewSessionTimer />

          <v-divider></v-divider>

          <v-list-item @click="endSession()">
            <v-list-item-content>
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-sheet>
  </v-menu>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import RenewSessionTimer from "./RenewSessionTimer.vue";

export default {
  components: { RenewSessionTimer },
  data() {
    return {
      sessionBadgeColor: "success",
    };
  },
  computed: {
    ...mapGetters("auth", ["getUser"]),
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    async endSession() {
      return this.logout();
    },
  },
};
</script>

<style lang="scss" scoped></style>
